@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* 

300 - light
400 - normal
600 - semi bold
700 - bold

*/

:root {
  --grey: #211f1f;
  --white: #ffffff;
  --orange: #f8872f;
  --pink: #f91070;
  --purple: #482f86;
  --lightGreen: #25d8be;
  --green: #7dff00;
}
/*
  --pink: #CC0075;
  --orange: #25D8BE;
  --purple: #7DEF00;
*/

body {
  margin: 0;
  background-color: var(--grey);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: var(--white);
  overflow-x: hidden;
  width: 100%;
}
* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  padding: 0 50px;
  width: 1200px;
  margin: 0 auto;
}
.App .container {
  display: flex;
  z-index: 1;
  position: relative;
  align-items: flex-start;
}

.Heading {
  height: min-content;
  animation: fade-from-left 600ms ease-out 0ms forwards;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.Heading h1 {
  font-size: 66px;
  font-weight: 600;
  line-height: 57px;
  margin-bottom: 10px;
  margin-top: 70px;
}
.Heading h1 .AnimatedText {
  display: inline-block;
  font-weight: 700;
  margin: 0;
  unicode-bidi: isolate;
  overflow: hidden;
  position: relative;
}
.AnimatedText span {
  color: var(--lightGreen);
  position: relative;
  display: inline-block;
  translate: none;
  rotate: none;
  scale: none;
  opacity: 1;
}
.Heading .exclamation {
  color: var(--lightGreen);
}
.AnimatedText .AnimatedLetter1 {
  animation: animationLetters 5s infinite;
  animation-delay: 6s;
}
.AnimatedText .AnimatedLetter2 {
  animation: animationLetters 5s infinite;
  animation-delay: 6.1s;
}
.AnimatedText .AnimatedLetter3 {
  animation: animationLetters 5s infinite;
  animation-delay: 6.2s;
}
.AnimatedText .AnimatedLetter4 {
  animation: animationLetters 5s infinite;
  animation-delay: 6.4s;
}
.AnimatedText .AnimatedLetter5 {
  animation: animationLetters 5s infinite;
  animation-delay: 6.5s;
}
.AnimatedText .AnimatedLetter6 {
  animation: animationLetters 5s infinite;
  animation-delay: 6.6s;
}
.AnimatedText .AnimatedLetter7 {
  animation: animationLetters 5s infinite;
  animation-delay: 6.7s;
}
.AnimatedText .AnimatedLetter8 {
  animation: animationLetters 5s infinite;
  animation-delay: 6.8s;
}
.AnimatedText .AnimatedLetter9 {
  animation: animationLetters 5s infinite;
  animation-delay: 6.9s;
}
.AnimatedText .AnimatedLetter10 {
  animation: animationLetters 5s infinite;
  animation-delay: 7s;
}
.AnimatedText .AnimatedLetter11 {
  animation: animationLetters 5s infinite;
  animation-delay: 7.1s;
}

@keyframes animationLetters {
  0% {
    transform: translate3d(0px, 0%, 0px);
  }
  2% {
    opacity: 1;
    transform: translate3d(0px, -100%, 0px);
  }
  3% {
    opacity: 0;
    transform: translate3d(0px, -100%, 0px);
  }
  5% {
    opacity: 0;
    transform: translate3d(0px, 100%, 0px);
  }
  7% {
    opacity: 0;
    color: var(--green);
    transform: translate3d(0px, 100%, 0px);
  }
  10% {
    opacity: 1;
    color: var(--green);
    transform: translate3d(0px, 0%, 0px) ease;
  }
  15% {
    opacity: 1;
    color: var(--green);
    transform: translate3d(0px, 0%, 0px);
  }
  17% {
    opacity: 1;
    color: var(--green);
    transform: translate3d(0px, -100%, 0px);
  }
  18% {
    opacity: 0;
    transform: translate3d(0px, -100%, 0px);
  }
  19% {
    opacity: 0;
    color: var(--pink);
    transform: translate3d(0px, 100%, 0px);
  }
  22% {
    opacity: 1;
    color: var(--pink);
    transform: translate3d(0px, 0%, 0px) ease;
  }
  27% {
    opacity: 1;
    color: var(--pink);
    transform: translate3d(0px, 0%, 0px);
  }
  29% {
    opacity: 1;
    color: var(--pink);
    transform: translate3d(0px, -100%, 0px);
  }
  30% {
    opacity: 0;
    transform: translate3d(0px, -100%, 0px);
  }
  31% {
    color: var(--lightGreen);
    opacity: 0;
    transform: translate3d(0px, 100%, 0px);
  }
  32% {
    opacity: 1;
    transform: translate3d(0px, 0%, 0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0%, 0px);
  }
}
.Paragraph {
  font-size: 20px;
  padding-right: 160px;
  animation: fade-from-left 600ms ease-out 200ms forwards;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.Paragraph p:first-of-type {
  font-weight: 600;
}
.Paragraph p:last-of-type {
  font-weight: 300;
}
.Paragraph p:last-of-type span {
  font-weight: 700;
  color: var(--lightGreen);
}

.Banner {
  padding-right: 80px;
  animation: fade-from-left 600ms ease-out 400ms forwards;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.Banner img {
  margin-top: -85px;
  margin-left: -40px;
  width: 100%; /* Allows the image to scale with the width of its container */
  height: auto; /* Maintains the aspect ratio */
  max-width: 100%;
}
.BookingForm {
  margin-top: -65px;
  padding: 20px 40px;
  min-width: 490px;
  background: linear-gradient(
    180deg,
    rgba(23, 21, 21, 1) 0%,
    rgba(33, 31, 31, 0.6) 100%
  );
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0px -10px 40px -20px rgba(6, 145, 119, 0.2);
  animation: fade-from-right 600ms ease-out 200ms forwards;
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.BookingForm h2 {
  font-size: 24px;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 20px;
}
.BookingForm .note {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}
.BookingForm .date-picker {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.BookingForm .date-picker img {
  width: 40px;
  height: 34px;
}
.BookingForm .date-picker select {
  display: inline-block;
  margin-top: 0;
  opacity: 1;
  font-weight: 200;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("./assets/arrow.svg");
  background-repeat: no-repeat;
  background-position: right 50%;
  background-size: 15px;
  padding-right: 30px;
  color: var(--white);
}
.BookingForm .date-picker select option {
  color: #000;
}
.BookingForm .date-picker select:hover {
  cursor: pointer;
}
.BookingForm input,
.BookingForm textarea {
  display: block;
  width: 100%;
}
.BookingForm select,
.BookingForm input,
.BookingForm textarea {
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.6);
  padding-bottom: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 400;
}
.BookingForm textarea {
  min-height: 35px;
  height: auto;
  resize: none;
}
.BookingForm .date-picker > div {
  margin-left: 10px;
}
.BookingForm .date-picker #time {
  margin-left: 20px;
}
.BookingForm .form-group {
  position: relative;
  margin-top: 15px;
  margin-bottom: 25px;
}
.BookingForm .form-label {
  position: absolute;
  left: 0;
  z-index: 10;
  font-size: 19px;
  opacity: 0.4;
  font-weight: 300;
  transition: transform 150ms ease-out, font-size 150ms ease-out,
    opacity 150ms ease-out;
}
.BookingForm .form-group.focused .form-label {
  transform: translateY(-75%);
  font-size: 0.7em;
  opacity: 0.6;
}
.BookingForm .form-group.focused .form-input {
  /* font-size: 16px; */
}
.BookingForm.success {
  position: relative;
}
.BookingForm.success h2 {
  color: var(--pink);
}
.BookingForm.success #airplane {
  position: absolute;
  width: 90px;
  right: 180px;
  transform: rotateY(180deg) rotateZ(20deg);
}
.BookingForm.success #lines {
  position: absolute;
  width: 250px;
  right: 0px;
  bottom: -120px;
  transform: rotateY(180deg) rotateZ(30deg);
}

.line {
  margin-top: 1px;
  height: 3px;
  width: 100%;
  background: linear-gradient(90deg, var(--pink) 0%, var(--orange) 100%);
  transition: all 1s ease-in;
}

.BookingForm button {
  position: relative;
  display: block;
  width: 140px;
  margin: 30px auto;
  font-size: 22px;
  font-weight: 500;
  padding: 15px 8px;
  border-radius: 10px;
  color: var(--white);
  background-color: var(--pink);
  cursor: pointer;
  border: none;
  transition: all 0.2s ease-out;
}

.BookingForm button.loading {
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.BookingForm .spinner {
  display: block;
  width: 34px;
  height: 34px;
  position: absolute;
  top: 8px;
  left: calc(50% - 17px);
  background: transparent;
  box-sizing: border-box;
  border-top: 4px solid white;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 100%;
  animation: spin 0.6s ease-out infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.BookingForm.has-error .line {
  background: linear-gradient(90deg, #8a0000 0%, #ff0000 100%);
}
.BookingForm.has-error .form-label {
  color: #ff0000;
}

.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.Footer .GamanzaIcon {
  width: 200px;
  height: auto;
}

.DotsContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background: var(--grey);
  display: flex;
  top: 0;
  left: 0;
  z-index: -9;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.DotsContainer span {
  height: auto;
  position: absolute;
  opacity: 0.4;
}

.DotsContainer span:nth-child(odd) {
  animation: shooting 25s infinite 0s ease-in-out;
}
.DotsContainer span:nth-child(3) {
  animation: shooting 26s infinite 0.2s ease-in-out;
}
.DotsContainer span:nth-child(7) {
  animation: shooting 30s infinite 10s ease-in-out;
}
.DotsContainer span:nth-child(13) {
  animation: shooting 28s infinite 6s ease-in-out;
}
.DotsContainer span:nth-child(5) {
  animation: shooting 30s infinite 4s ease-in-out;
}
.DotsContainer span:nth-child(5) {
  animation: shooting 32s infinite 8s ease-in-out;
}
.DotsContainer span:nth-child(9) {
  animation: shooting 32s infinite 2s ease-in-out;
}
.DotsContainer span:nth-child(11) {
  animation: shooting 20s infinite 0.6s ease-in-out;
}
.DotsContainer span:nth-child(15) {
  animation: shooting 26s infinite 12s ease-in-out;
}
.DotsContainer span:nth-child(even) {
  animation: even 4s infinite 0.9s ease-in-out;
}
.DotsContainer span:nth-child(10) {
  animation: dotsOdd 2s infinite 0.9s ease-in-out;
}
.DotsContainer span:nth-child(16) {
  animation: even 3s infinite 0.7s ease-in-out;
}
.DotsContainer span:nth-child(20) {
  animation: even 3.5s infinite 0.3s ease-in-out;
}
.DotsContainer span:nth-child(20) {
  animation: even 3.5s infinite ease-in-out;
}

@keyframes dotsOdd {
  50% {
    transform: translate3d(-2px, 4px, 2px);
  }
}
@keyframes even {
  50% {
    transform: translate3d(2px, 7px, 2px);
  }
}

@keyframes shooting {
  0% {
    transform: translateY(100vh) rotate(45deg) scale(1);
  }
  90% {
    opacity: 1;
    transform: translateY(-100vh) rotate(45deg);
  }
  91% {
    opacity: 0;
    transform: translateY(-100vh) rotate(45deg);
  }
  100% {
    opacity: 0;
    transform: translateY(100vh) rotate(45deg);
  }
}

.DotsContainer img {
  width: 100%;
  height: auto;
}

.IntroVideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; /* Ensure it covers the entire viewport */
  background-color: black;
}
.IntroVideo video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  animation: disappear 100ms ease-out 2.2s forwards;
}
.IntroVideo img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  object-fit: contain;
  animation: zoom-and-fade 650ms ease-out 2.2s forwards;
}
.AppContainer {
  animation: zoom-in 300ms linear forwards;
}
@keyframes zoom-and-fade {
  0% {
    transform: scale(0.3);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes disappear {
  100% {
    opacity: 0;
  }
}

@keyframes fade-from-left {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fade-from-right {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 1200px) {
  .App {
    width: 100%;
  }
  .Paragraph {
    padding-right: 5%;
  }
  .Banner {
    padding-right: 2.5%;
  }
}
@media (max-width: 1000px) {
  .BookingForm {
    min-width: 445px;
    margin-top: -30px;
  }
  .Banner img {
    margin-top: -35px;
    margin-left: 0;
  }
  .Heading h1 {
    font-size: 50px;
    line-height: 45px;
  }
}
@media (max-width: 850px) {
  .App .container {
    flex-direction: column;
    align-items: center;
  }
  .Banner {
    display: none;
  }
  .Paragraph {
    padding-right: 0;
  }
  .Paragraph p:last-of-type {
    text-align: justify;
  }
  .BookingForm {
    margin-top: 20px;
    width: 100%;
    animation: fade-from-left 600ms ease-out 400ms forwards;
  }
}
@media (max-width: 500px) {
  .App {
    padding: 0 20px;
  }
  .Heading h1 {
    font-size: 39px;
    line-height: 36px;
    margin-top: 40px;
  }
  .Banner {
    display: none;
  }
  .Paragraph {
    padding-right: 0;
    font-size: 17px;
  }
  .Paragraph p:last-of-type {
    text-align: justify;
  }
  .BookingForm {
    margin-top: 20px;
    width: 100%;
    min-width: unset;
    padding: 20px 30px;
  }
  .BookingForm h2 {
    font-size: 19px;
  }
  .BookingForm select,
  .BookingForm input,
  .BookingForm textarea {
    font-size: 15px;
  }
  .BookingForm .form-label {
    font-size: 15px;
  }
  .BookingForm button {
    font-size: 19px;
    padding: 10px 8px;
  }
  .BookingForm .date-picker select {
    padding-right: 20px;
    font-size: 14px;
  }
  .BookingForm.success #airplane {
    bottom: -120px;
  }
  .BookingForm.success #lines {
    bottom: -160px;
  }
}
@media (max-width: 420px) {
  .BookingForm {
    width: 110%;
    padding: 20px 20px;
  }
  .BookingForm .form-label {
    font-size: 15px;
  }
  .BookingForm button {
    font-size: 19px;
    padding: 10px 8px;
  }
  .BookingForm .date-picker img {
    width: 30px;
    height: 27px;
  }
}
